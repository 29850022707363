import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  MenuItem,
  MenuSub,
  MenuSubContent,
  MenuSubTrigger,
} from '@iheartradio/web.accomplice/menu';
import { ampContract } from '@iheartradio/web.api/amp';
import { AddIcon, LoadingIcon } from '@iheartradio/web.companion';
import { isFunction, isNil } from '@iheartradio/web.utilities';
import {
  removeNilValuesFromObject,
  toFormData,
} from '@iheartradio/web.utilities/object';
import { useFetcher } from '@remix-run/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import {
  type CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { $path } from 'remix-routes';

import type { PlaylistCollection } from '~app/api/types';
import type {
  AddToCollectionAction,
  AddToCollectionActionData,
} from '~app/routes/api.v1.collection.add';
import type { ListCollectionsLoader } from '~app/routes/api.v1.collection.list';
import { isDoneFetching } from '~app/utilities/fetcher';

import {
  type CreatePlaylistSubmitCallback,
  CreatePlaylistDialog,
} from '../dialogs/create-playlist-dialog';

export type AddToCollectionSubmitCallback = (
  playlist?: AddToCollectionActionData,
) => void;

export type AddToPlaylistMenuItemsProps = {
  tracks?: number[];
  albumId?: number;
  triggerText?: string;
  onAddSubmit?: AddToCollectionSubmitCallback;
  onCreateSubmit: CreatePlaylistSubmitCallback;
};

type AddToPlaylistMenuRow =
  | { type: 'create-playlist' }
  | { type: 'playlist'; data: PlaylistCollection };

const playlistLibraryRoute = $path('/api/v1/collection/list');

export const AddToPlaylistSubMenu = (props: AddToPlaylistMenuItemsProps) => {
  const {
    tracks,
    albumId,
    onAddSubmit,
    onCreateSubmit,
    triggerText = 'Add to playlist',
  } = props;
  const [playlists, setPlaylists] = useState<Array<PlaylistCollection>>([]);

  const addToCollectionFetcher = useFetcher<AddToCollectionAction>();
  const playlistsFetcher = useFetcher<ListCollectionsLoader>();

  let rows: Array<AddToPlaylistMenuRow> = playlists.map(playlist => ({
    type: 'playlist',
    data: playlist,
  }));
  rows = [{ type: 'create-playlist' }, ...rows];

  const parentRef = useRef(null);

  const nextPageKey = playlistsFetcher?.data?.data?.links?.nextPageKey;

  const isLoadingMoreResults = playlistsFetcher.state === 'loading';

  // Assume we have a next page if we haven't fetched any data. Otherwise, look for a "nextPageKey"
  const hasNextPage =
    isNil(playlistsFetcher?.data) ? true : (
      playlistsFetcher?.data?.ok && Boolean(nextPageKey)
    );

  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? rows.length + 1 : rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 5,
  });

  const items = rowVirtualizer.getVirtualItems();

  const fetchMore = useCallback(() => {
    if (playlistsFetcher.state === 'idle' && hasNextPage) {
      const searchParams = new URLSearchParams(
        ampContract.v3.collection.getCollections.query
          .transform(removeNilValuesFromObject)
          .parse({
            pageKey: nextPageKey,
            playlistFilter: 'created',
          }),
      );
      playlistsFetcher.load(`${playlistLibraryRoute}?${searchParams}`);
    }
  }, [hasNextPage, nextPageKey, playlistsFetcher]);

  useEffect(() => {
    const newlyFetchedPlaylists = playlistsFetcher?.data?.data?.data ?? [];

    if (isDoneFetching(playlistsFetcher)) {
      setPlaylists(existingPlaylists => [
        ...existingPlaylists,
        ...newlyFetchedPlaylists,
      ]);
    }
  }, [playlistsFetcher]);

  useEffect(() => {
    const lastItem = items.at(-1);

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= rows.length - 1 && hasNextPage) {
      fetchMore();
    }
  }, [hasNextPage, fetchMore, isLoadingMoreResults, rows.length, items]);

  useEffect(() => {
    if (isDoneFetching(addToCollectionFetcher) && isFunction(onAddSubmit)) {
      onAddSubmit(addToCollectionFetcher?.data);
    }
  }, [addToCollectionFetcher, onAddSubmit]);

  return (
    <MenuSub
      data-test="add-to-playlist-sub-menu"
      onOpenChange={open => {
        if (open) {
          fetchMore();
          rowVirtualizer.measure();
        }
      }}
    >
      <MenuSubTrigger>{triggerText}</MenuSubTrigger>
      <MenuSubContent ref={parentRef}>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: '100%',
            position: 'relative',
          }}
        >
          {items.map(virtualRow => {
            const isLoaderRow = virtualRow.index > rows.length - 1;
            const row = rows.at(virtualRow.index);

            const menuItemStyles: CSSProperties = {
              position: 'absolute',
              top: 0,
              left: 0,
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            };

            return (
              isLoaderRow ?
                <MenuItem
                  data-index={virtualRow.index}
                  key={virtualRow.key}
                  style={menuItemStyles}
                >
                  <Flex
                    alignItems="center"
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                  >
                    <LoadingIcon css={{ marginLeft: 'unset' }} size="24" />
                  </Flex>
                </MenuItem>
              : !row ? null
              : row.type === 'create-playlist' ?
                <CreatePlaylistDialog
                  albumId={albumId}
                  key={virtualRow.key}
                  onSubmit={onCreateSubmit}
                  tracks={tracks}
                  trigger={
                    <MenuItem
                      data-index={virtualRow.index}
                      data-test="create-playlist-menu-item"
                      key={virtualRow.key}
                      onSelect={event => {
                        // This ensures that clicking the MenuItem doesn't close the Menu and only opens the Dialog
                        event?.preventDefault();
                      }}
                      style={menuItemStyles}
                    >
                      <AddIcon />
                      Create new playlist
                    </MenuItem>
                  }
                />
              : row.type === 'playlist' ?
                <MenuItem
                  data-index={virtualRow.index}
                  key={virtualRow.key}
                  onSelect={event => {
                    // This ensures that clicking the MenuItem doesn't close the Menu and only opens the Dialog
                    event?.preventDefault();

                    const namedAction = tracks?.length ? 'byTracks' : 'byAlbum';

                    addToCollectionFetcher.submit(
                      toFormData({
                        tracks,
                        collectionId: row.data.id,
                        intent: namedAction,
                        albumId,
                      }),
                      {
                        method: 'POST',
                        action: $path('/api/v1/collection/add'),
                      },
                    );
                  }}
                  style={menuItemStyles}
                >
                  {row.data.name}
                </MenuItem>
              : null
            );
          })}
        </div>
      </MenuSubContent>
    </MenuSub>
  );
};
