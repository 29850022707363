import type { Fetcher } from '@remix-run/react';

export function isIdle(fetcher: Fetcher) {
  return fetcher.state === 'idle';
}

export function isSubmitting(fetcher: Fetcher) {
  return fetcher.state === 'submitting';
}

export function isDoneFetching(fetcher: Fetcher) {
  return fetcher.state === 'idle' && fetcher.data != null;
}

export function isLoading(fetcher: Fetcher) {
  return fetcher.state === 'loading';
}
